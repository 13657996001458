import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Colors system leverages a purposeful set of color styles as the perfect starting point for any brand or project.
When it comes to color, contrast is critical for ensuring text is legible.`}</p>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p><strong parentName="p">{`Info`}</strong>{`: The android legion SDK does not provide colors for your tribe or squad, you have to override the default color according to your tribe or squad`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Color can be used in every component or widget by applying value `}<inlineCode parentName="p">{`?attr/{color_token}`}</inlineCode>{` .`}</p>
    <pre><code parentName="pre" {...{}}>{`android:textColor="?attr/colorSecondary900"
`}</code></pre>
    <p>{`You can set the color of the widget or components from kotlin file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
context.requiredColor(UiKitAttr.{colorToken})
...
`}</code></pre>
    <p>{`You can also set your preferences color by override or register them in your themes .`}</p>
    <pre><code parentName="pre" {...{}}>{`<style name="YourTheme" parent="Legion.Light">
...
    <item name="colorPrimary900">@color/primary_900</item>
    <item name="colorPrimary800">@color/primary_800</item>
    <item name="colorPrimary700">@color/primary_700</item>
    <item name="colorSecondary900">@color/secondary_900</item>
    <item name="colorSecondary800">@color/secondary_800</item>
    <item name="colorSecondary700">@color/secondary_700</item>
    <item name="colorTertiary_900">@color/black_900</item>
    <item name="colorTertiary_800">@color/black_800</item>
    <item name="colorTertiary_700">@color/black_700</item>
    <item name="success_900">@color/success_900</item>
    <item name="success_800">@color/success_800</item>
    <item name="success_700">@color/success_700</item>
    <item name="success_600">@color/success_600</item>
    <item name="warning_900">@color/warning_900</item>
    <item name="warning_800">@color/warning_800</item>
    <item name="warning_700">@color/warning_700</item>
    <item name="info_900">@color/info_900</item>
    <item name="info_800">@color/info_800</item>
    <item name="info_700">@color/info_700</item>
    <item name="error_900">@color/error_900</item>
    <item name="error_800">@color/error_800</item>
    <item name="error_700">@color/error_700</item>
...
</style>

`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3>{`Color Primary`}</h3>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary700 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary600 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary500 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorPrimary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorPrimary25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="colorPrimary900">@color/primary_900</item>
    <item name="colorPrimary800">@color/primary_800</item>
    <item name="colorPrimary700">@color/primary_700</item>
    <item name="colorPrimary600">@color/primary_600</item>
    <item name="colorPrimary500">@color/primary_500</item>
    <item name="colorPrimary400">@color/primary_400</item>
    <item name="colorPrimary300">@color/primary_300</item>
    <item name="colorPrimary200">@color/primary_200</item>
    <item name="colorPrimary100">@color/primary_100</item>
    <item name="colorPrimary50">@color/primary_50</item>
    <item name="colorPrimary25">@color/primary_25</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h3>{`Color Secondary`}</h3>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary700 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary600 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary500 `}<strong parentName="td"><em parentName="strong">{`or`}</em></strong>{` colorSecondary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorSecondary25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="colorSecondary900">@color/secondary_900</item>
    <item name="colorSecondary800">@color/secondary_800</item>
    <item name="colorSecondary700">@color/secondary_700</item>
    <item name="colorSecondary600">@color/secondary_600</item>
    <item name="colorSecondary500">@color/secondary_500</item>
    <item name="colorSecondary400">@color/secondary_400</item>
    <item name="colorSecondary300">@color/secondary_300</item>
    <item name="colorSecondary200">@color/secondary_200</item>
    <item name="colorSecondary100">@color/secondary_100</item>
    <item name="colorSecondary50">@color/secondary_50</item>
    <item name="colorSecondary25">@color/secondary_25</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h3>{`Color Tertiary`}</h3>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`900 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`800 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_700`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_500`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`400 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`300 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`200 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`100 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`50 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colorTertiary`}<em parentName="td">{`25 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` black_25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`white`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`black`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="colorTertiary_900">@color/black_900</item>
    <item name="colorTertiary_800">@color/black_800</item>
    <item name="colorTertiary_700">@color/black_700</item>
    <item name="colorTertiary_600">@color/black_600</item>
    <item name="colorTertiary_500">@color/black_500</item>
    <item name="colorTertiary_400">@color/black_400</item>
    <item name="colorTertiary_300">@color/black_300</item>
    <item name="colorTertiary_200">@color/black_200</item>
    <item name="colorTertiary_100">@color/black_100</item>
    <item name="colorTertiary_50">@color/black_50</item>
    <item name="colorTertiary_25">@color/black_25</item>
    <item name="white">@color/white</item>
    <item name="black">@color/black</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h3>{`Color Accent`}</h3>
    <p>{`Accent colours communicate important status to the user such as success, information,
warning or error. These colours should be used paired with text, an icon or both to
fulfill their purpose in the best way possible.`}</p>
    <br />
    <br />
    <h4>{`Success`}</h4>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation.
If you’re using green as your primary color, it can be helpful to introduce a different
hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` success_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success_25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="success_900">@color/success_900</item>
    <item name="success_800">@color/success_800</item>
    <item name="success_700">@color/success_700</item>
    <item name="success_600">@color/success_600</item>
    <item name="success_500">@color/success_500</item>
    <item name="success_400">@color/success_400</item>
    <item name="success_300">@color/success_300</item>
    <item name="success_200">@color/success_200</item>
    <item name="success_100">@color/success_100</item>
    <item name="success_50">@color/success_50</item>
    <item name="success_25">@color/success_25</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h4>{`Warning`}</h4>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”.
These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` warning_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`warning_25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="warning_900">@color/warning_900</item>
    <item name="warning_800">@color/warning_800</item>
    <item name="warning_700">@color/warning_700</item>
    <item name="warning_600">@color/warning_600</item>
    <item name="warning_500">@color/warning_500</item>
    <item name="warning_400">@color/warning_400</item>
    <item name="warning_300">@color/warning_300</item>
    <item name="warning_200">@color/warning_200</item>
    <item name="warning_100">@color/warning_100</item>
    <item name="warning_50">@color/warning_50</item>
    <item name="warning_25">@color/warning_25</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h4>{`Information`}</h4>
    <p>{`Infomation colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` info_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`info_25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="info_900">@color/info_900</item>
    <item name="info_800">@color/info_800</item>
    <item name="info_700">@color/info_700</item>
    <item name="info_600">@color/info_600</item>
    <item name="info_500">@color/info_500</item>
    <item name="info_400">@color/info_400</item>
    <item name="info_300">@color/info_300</item>
    <item name="info_200">@color/info_200</item>
    <item name="info_100">@color/info_100</item>
    <item name="info_50">@color/info_50</item>
    <item name="info_25">@color/info_25</item>
    ...
</style>
`}</code></pre>
    <br />
    <br />
    <h4>{`Error`}</h4>
    <p>{`Error colors are used across error states and in “destructive” actions.
They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token list avalaibe on android`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_900`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_800`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`700 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_pressed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`600 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}<em parentName="td">{`500 `}{`*`}{`*`}{`_`}{`or`}</em>{`*`}{`*`}{` error_normal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_400`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error_25`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Override Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="error_900">@color/error_900</item>
    <item name="error_800">@color/error_800</item>
    <item name="error_700">@color/error_700</item>
    <item name="error_600">@color/error_600</item>
    <item name="error_500">@color/error_500</item>
    <item name="error_400">@color/error_400</item>
    <item name="error_300">@color/error_300</item>
    <item name="error_200">@color/error_200</item>
    <item name="error_100">@color/error_100</item>
    <item name="error_50">@color/error_50</item>
    <item name="error_25">@color/error_25</item>
    ...
</style>
`}</code></pre>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/color-system/color_system.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      